<template>
    <div class="main-contact-container">
        <div class="contact-container">
            <a class="contact-logo">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1080 143.9" style="enable-background:new 0 0 1080 143.9;" xml:space="preserve">
                    <g>
                        <g transform="translate(2.845527, 112.226025)">
                            <g>
                                <path d="M60.3-105.5c43.2-12.1,64.3,35.4,64.3,35.4v-20.6c0,0-20.6-28.3-64.7-16.2C22.5-97,0.6-58.2,10.3-20.6
                                    c10.7,37.6,48.3,60.4,86.1,49.5c14.3-3.8,26.9-11.9,35.6-22.8V-19c-2.8,21.4-14.1,41-36,46.9C62.3,37.1,33.8,5.8,25.5-24.5
                                    C18-54.6,26.5-96.2,60.3-105.5z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g transform="translate(106.334865, 112.226025)">
                            <g>
                                <path d="M94.6-107.3C57.2-97.2,34.9-58.8,44.7-21.2c10.3,38,48.5,60.4,85.7,49.9c37.6-9.9,60-48.9,49.5-85.9
                                    C170.3-94.8,131.7-117.8,94.6-107.3z M94.8-105.7c34.1-9.5,61.8,22,69.9,52.5c7.7,30.1-0.8,71.9-34.6,80.6
                                    C96.6,36.3,68.7,4.6,60.8-25.3C52.7-55.6,61.2-97,94.8-105.7z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g transform="translate(221.999437, 112.226025)">
                            <g>
                                <path d="M80.9-109.2V30.5h14.8v-138.4c21.4,0,37.2,16,49.1,38.6c14.7,28.7,22,68.3,22,99.8h14.8v-139.8h-14.8V4.4
                                    c-3-25.5-9.5-52.9-21.2-74.4c-11.5-23-28.3-39.2-49.9-39.2H80.9z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g transform="translate(307.968352, 112.226025)">
                            <g>
                                <path d="M156.7,30.5h15.2v-138.4c21,0.2,33.7,1.4,47.1,13.9v-15.4H109.6V-94c13.1-12.5,25.9-13.7,47.1-13.9V30.5z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g transform="translate(400.470317, 112.226025)">
                            <g>
                                <path d="M236.6,30.5h16.6l-59.2-139.8h-1.4c-15,25.5-32.9,61.6-43.4,89.9c-4.8,12.7-7.5,23.8-8.5,32.5c-1,8.9,1.2,14.8,6.5,17.2
                                    c1.4,1.2,2,1.2,3.2,1.4c8.9,0.4,12.3-6.9,16-16.8c6.3-14.5,14.1-34.8,47.7-38L236.6,30.5z M213.7-24.5
                                    c-34.2,3.4-42.2,24.2-48.7,39c-3.2,9.1-6.3,16-14.3,15.4c-1-0.6-1.6-0.6-2.8-1c-4.8-1.6-6.1-7.7-5.7-15.8
                                    c1-8.5,3.8-19.4,8.5-32.1c8.3-23,21.8-49.9,33.9-73.2L213.7-24.5z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g transform="translate(495.496379, 112.226025)">
                            <g>
                                <path d="M224.6-105.5c43.2-12.1,64.3,35.4,64.3,35.4v-20.6c0,0-20.6-28.3-64.7-16.2c-37.4,9.9-59.4,48.7-49.7,86.3
                                    c10.7,37.6,48.3,60.4,86.1,49.5c14.3-3.8,26.9-11.9,35.6-22.8V-19c-2.8,21.4-14.1,41-36,46.9c-33.7,9.1-62.2-22.2-70.5-52.5
                                    C182.2-54.6,190.7-96.2,224.6-105.5z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g transform="translate(598.985716, 112.226025)">
                            <g>
                                <path d="M253.7,30.5H269v-138.4c21,0.2,33.7,1.4,47.1,13.9v-15.4H206.6V-94c13.1-12.5,25.9-13.7,47.1-13.9V30.5z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g transform="translate(691.487681, 112.226025)">
                            <g>
                                <path d="M289.7-107.3c-37.4,10.1-59.8,48.5-49.9,86.1c10.3,38,48.5,60.4,85.7,49.9c37.6-9.9,60-48.9,49.5-85.9
                                    C365.3-94.8,326.7-117.8,289.7-107.3z M289.9-105.7c34.1-9.5,61.8,22,69.9,52.5c7.7,30.1-0.8,71.9-34.6,80.6
                                    c-33.5,8.9-61.4-22.8-69.3-52.7C247.7-55.6,256.2-97,289.9-105.7z"/>
                            </g>
                        </g>
                    </g>
                </svg>

            </a>

            <div class="scrollingText">
                <div class="tickerWrap">
                    <div class="leftTicker">
                        <a> 
                            <a onclick="window.open('https://www.instagram.com/theplacedigital/', '_blank').focus()" style="margin-top: 10px;">INSTAGRAM</a>  
                            <a style="margin-top: 10px;">LINKEDIN</a>   
                            <a onclick="window.open('https://www.pinterest.es/Theplacedigital/', '_blank').focus()" style="margin-top: 10px;">PINTEREST</a>
                            <a onclick="window.open('https://www.instagram.com/theplacedigital/', '_blank').focus()" style="margin-top: 10px;">INSTAGRAM</a>  
                            <a style="margin-top: 10px;">LINKEDIN</a>   
                            <a onclick="window.open('https://www.pinterest.es/Theplacedigital/', '_blank').focus()" style="margin-top: 10px;">PINTEREST</a>
                            <a onclick="window.open('https://www.instagram.com/theplacedigital/', '_blank').focus()" style="margin-top: 10px;">INSTAGRAM</a>  
                            <a style="margin-top: 10px;">LINKEDIN</a>   
                            <a onclick="window.open('https://www.pinterest.es/Theplacedigital/', '_blank').focus()" style="margin-top: 10px;">PINTEREST</a>
                            <a onclick="window.open('https://www.instagram.com/theplacedigital/', '_blank').focus()" style="margin-top: 10px;">INSTAGRAM</a>  
                            <a style="margin-top: 10px;">LINKEDIN</a>   
                            <a onclick="window.open('https://www.pinterest.es/Theplacedigital/', '_blank').focus()" style="margin-top: 10px;">PINTEREST</a>
                            <a onclick="window.open('https://www.instagram.com/theplacedigital/', '_blank').focus()" style="margin-top: 10px;">INSTAGRAM</a>  
                            <a style="margin-top: 10px;">LINKEDIN</a>   
                            <a onclick="window.open('https://www.pinterest.es/Theplacedigital/', '_blank').focus()" style="margin-top: 10px;">PINTEREST</a>
                            <a onclick="window.open('https://www.instagram.com/theplacedigital/', '_blank').focus()" style="margin-top: 10px;">INSTAGRAM</a>  
                            <a style="margin-top: 10px;">LINKEDIN</a>   
                            <a onclick="window.open('https://www.pinterest.es/Theplacedigital/', '_blank').focus()" style="margin-top: 10px;">PINTEREST</a>
                            <a onclick="window.open('https://www.instagram.com/theplacedigital/', '_blank').focus()" style="margin-top: 10px;">INSTAGRAM</a>  
                            <a style="margin-top: 10px;">LINKEDIN</a>   
                            <a onclick="window.open('https://www.pinterest.es/Theplacedigital/', '_blank').focus()" style="margin-top: 10px;">PINTEREST</a>
                        </a>
                    </div>
                </div>
            </div>

            <div class="contact-footer">
                <div class="individual-contact-footer">
                    <a style="font-weight: bold; font-size: 10px;">EMAIL</a>
                    <a href="mailto:contacto.theplacedigital@gmail.com">contacto.theplacedigital@gmail.com</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>

<style scoped>
.contact-logo {
    padding: 10px 5px 0px 5px;
}

.main-contact-container {
    height: 100%;
    display: flex;
}
.contact-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.contact-title {
    font-family: 'Carmorant';
    font-size: 3.5em;
    margin: 0;
}
.contact-footer {
    padding: 10px 0px;
    height: 70px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px;
    font-family: 'Carmorant';
    color: black;
    text-decoration: none;
}

a[href^="tel"]{
    color:inherit;
    text-decoration:none;
}
a[href^="mailto"]{
    color:inherit;
    text-decoration:none;
}

.individual-contact-footer {
    display: flex;
    flex-direction: column;
}

.scrollingText {
  font-size: 1.5rem;
  writing-mode: vertical-rl;
  overflow: hidden;
  height: 100%;
  width: 100%;
  transform: rotate(180deg);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


@-webkit-keyframes leftTicker {
  0% {
    -webkit-transform: translate3d(0, -70%, 0);
    transform: translate3d(0, -70%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
}

@keyframes leftTicker {
  0% {
    -webkit-transform: translate3d(0, -70%, 0);
    transform: translate3d(0, -70%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
}

.tickerWrap {
  overflow: hidden;
  font-family: 'Cormorant', sans-serif;
  height: 100%;
  display: flex;
}

.tickerWrap .leftTicker {
  white-space: nowrap;

  -webkit-animation-iteration-count: infinite; 
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
 -webkit-animation-name: leftTicker;
         animation-name: leftTicker;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.leftTicker a {
    cursor: pointer;
}

</style>
  