<template>
    <div class="home">
        <div class="images-container">
          <div class="left-container">
              <h1 class="upper-text">
                THIS IS THE PLACE SI QUIERES DAR EL SALTO A LO <a :style="fontStyle">digital</a>
              </h1>
              <div class="left-banner">
              </div>
              <div style="display: flex; justify-content: space-between; width: 100%; margin-top: 5px;">
                <a style="font-size: 12px;">Contacto</a>
                <hr style="width: 40px; margin-right: 0;">
              </div>
          </div>
          <div class="right-container">
            <div style="height: 100%; width: 100%;">
              <div class="right-banner">
              </div>
              <div style="display: flex; justify-content: space-between; width: 100%; margin-top: 5px;">
                <a style="font-size: 12px;">Servicios</a>
                <hr style="width: 40px; margin-right: 0;">
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-text">
          <a>IMPULSO DIGITAL, IMPACTO REAL</a>
        </div>

        <div class="scrollingText">
            <div class="tickerWrap">
                <div class="leftTicker">
                  <a><i>MARKETING</i> DIGITAL REDES CREATIVIDAD <i>THE PLACE</i> SOCIAL NETWORKS INSTAGRAM ADS FACEBOOK GOOGLE <i>ONLINE</i> PRESENCIA</a>
                </div>
                <div class="rightTicker">
                  <a>SOCIAL NETWORKS INSTAGRAM <i>THE PLACE</i> CREATIVIDAD ADS DIGITAL REDES <i>MARKETING</i> FACEBOOK GOOGLE <i>ONLINE</i> PRESENCIA</a>
                </div>
                <div class="leftTicker">
                  <a>DIGITAL REDES CREATIVIDAD <i>THE PLACE</i> SOCIAL NETWORKS INSTAGRAM ADS FACEBOOK GOOGLE <i>ONLINE</i> PRESENCIA <i>MARKETING</i></a>
                </div>
                <div class="rightTicker">
                  <a><i>THE PLACE</i> PRESENCIA INSTAGRAM ADS <i>ONLINE</i> FACEBOOK DIGITAL REDES CREATIVIDAD  SOCIAL NETWORKS GOOGLE <i>MARKETING</i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      fonts: ['Cormorant', 'Playfair', 'Syne'],
      currentFontIndex: 0,
    };
  },
  computed: {
    fontStyle() {
      return {
        fontFamily: this.fonts[this.currentFontIndex],
        fontSize: '18px',
        transition: 'font-family 0.3s',
      };
    },
  },
  methods: {
    changeFont() {
      if (this.currentFontIndex !== this.fonts.length - 1) this.currentFontIndex++;
      else this.currentFontIndex = 0;
    },
  },
  mounted() {
    setInterval(this.changeFont, 2000);
  },
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mainText {
  min-height: 60%;
}

.scrollingText {
  height: 40%;
  padding: 0px;
  font-size: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@-webkit-keyframes coverText {
  0% {
    z-index: 80;
  }

  100% {
    z-index: 110;
  }
}

@keyframes coverText {
  0% {
    z-index:80;
  }

  100% {
    z-index: 110;
  }
}


@-webkit-keyframes leftTicker {
  0% {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes leftTicker {
  0% {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@-webkit-keyframes rightTicker {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}

@keyframes rightTicker {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}

.tickerWrap {
  overflow: hidden;
  padding-left: 100%;
  box-sizing: content-box;
  font-family: 'Cormorant', sans-serif;
  line-height: 80%;
}

.tickerWrap .leftTicker {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;

  -webkit-animation-iteration-count: infinite; 
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
 -webkit-animation-name: leftTicker;
         animation-name: leftTicker;
  -webkit-animation-duration: 120s;
          animation-duration: 120s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.tickerWrap .rightTicker {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;

  -webkit-animation-iteration-count: infinite; 
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
 -webkit-animation-name: rightTicker;
         animation-name: rightTicker;
  -webkit-animation-duration: 120s;
          animation-duration: 120s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.left-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content:baseline;
  position: relative;
  padding: 20px 0px 20px 20px;
}


.right-container {
  padding-top: 40px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: space-between;
  flex-direction: column;
  padding: 20px;
}

.images-container {
  display: flex;
  height: 100%;
  margin-top: 20px;
}

.left-banner {
  background-image: url("../assets/home1.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  width: 100%;
}

.right-banner {
  background-image: url("../assets/home2.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  width: 100%;
}

.upper-text {
  font-family: 'Syne', sans-serif;
  color: rgb(41, 41, 41);
  font-size: 16px;
  text-align: right;
  line-height: 25px;
  margin-bottom: 40px;
  margin-top: 30px;

  -webkit-animation-iteration-count: infinite; 
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-name: coverText;
        animation-name: coverText;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.bottom-text {
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  justify-content: center;
  font-size: 12px;
  font-family: 'Syne' !important;
}



@media only screen and (min-width: 600px) {
    .upper-text {
        font-size: 32px;
    }
}
</style>
  