<template>
  <div class="container">
    <div class="navbar">
      <div class="dots" @click="tabSelected = 1">
        <span class="dot"></span>
        <span class="dot"></span>
        <span :class="[tabSelected !== 1 ? specialClass : activeClass]"></span>
      </div>
      <div class="tabs">
        <div class="tab" @click="tabSelected = 2">
          <a :class="[tabSelected === 2 ? 'bold-tab' : '']">SERVICIOS</a>
        </div>
        <div class="tab" @click="tabSelected = 3">
          <a :class="[tabSelected === 3 ? 'bold-tab' : '']">ABOUT</a>
        </div>
        <div class="tab" @click="tabSelected = 4">
          <a :class="[tabSelected === 4 ? 'bold-tab' : '']">CONTACTO</a>
        </div>
      </div>
    </div>
    <Home v-show="tabSelected === 1"/>
    <Services v-show="tabSelected === 2"/>
    <About v-show="tabSelected === 3"/>
    <Contact v-show="tabSelected === 4"/>
    <Inspiration v-show="tabSelected === 5"/>
  </div>
</template>

<script>
import Home from './Home.vue'
import Services from './Services.vue'
import About from './About.vue'
import Contact from './Contact.vue'
import Inspiration from './Inspiration.vue'
  
export default {
  name: 'Container',
  components: {
    Home,
    Services,
    About,
    Contact,
    Inspiration
  },

  data() {
    return {
      tabSelected: 1,
      activeClass: 'dot',
      specialClass: 'special-dot'
    }
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.container {
  padding: 0 0 2rem 0;
  background-color: #fefaf6;
  width: 90vw;
  height: 90vh;
  max-width: 600px;
  max-height: 1000px;
}

.dots {
  padding: 5px 0px;
  width: 85px;
}
.dots :hover {
  cursor: pointer;
}

.dot {
  height: 7px;
  width: 7px;
  margin-right: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.special-dot {
  height: 7px;
  width: 7px;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation-iteration-count: infinite; 
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
 -webkit-animation-name: colorChange;
         animation-name: colorChange;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.navbar {
  background: #fefaf6;
  border-bottom: 1px solid #bbb;
  display: flex;
  justify-content: space-between;
}

.tabs {
  display: flex;
  width: 95%;
  padding: 10px 10px 10px 0px;
}

.tab {
  min-width: 33%;
  height: 100%;
  text-align: center;
  display: grid;
  align-content: center;
}

.tab :hover {
  cursor: pointer;
}

.bold-tab {
  font-weight: 700;
}

.tab:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.37);
}

.tab a {
  font-size: 10px;
}

@-webkit-keyframes colorChange {
  0% {
    background-color: white;
  }

  100% {
        background-color: #C6E000;
  }
}



@keyframes colorChange {
  0% {
    background-color: white;
  }

  100% {
        background-color: #C6E000;
  }
}
</style>
